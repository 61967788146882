<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <div class="is-flex goods-info__header"
         ref="goodsInfo">
      <div class="mr16 header-item">
        <p class="title-mark pl12 mb20 mt-0">基础信息</p>
        <div class="is-flex ">
          <a-image :src="goods.cover"
                   alt="商品封面图"
                   width="242px"
                   height="136px" />
          <div class="ml20 gray-darker"
               :style="{flex: '1'}">
            <a-tooltip :title="goods.title"
                       :getPopupContainer="() => $refs.goodsInfo"
                       color="#fff">
              <p class="mb12 fw-500 text-overflow cursor-pointer"
                 :style="{width: '200px'}">{{goods.title}}</p>
            </a-tooltip>

            <p class="mb12"> <span class="gray-dark">商品类型：</span> {{ goods.type === 'course' ? '网校课程' : '网校班级' }}</p>
            <p class="mb12"><span class="gray-dark">商品价格：</span> {{ _.divide(goods.price, 100) }} 元</p>
            <p class="mb12"><span class="gray-dark">创建时间：</span>
              {{ $f.datetime(goods.createdTime, 'YYYY-MM-DD HH:mm') }}</p>
          </div>
        </div>
      </div>

      <div class="header-item">
        <p class="title-mark pl12 mb48 mt-0">统计数据</p>

        <div class="is-flex"
             :style="{justifyContent: 'space-evenly'}">
          <a-statistic title="购买人数"
                       :value="goods.buyerNum" />

          <a-statistic title="成交金额（元）"
                       :value="_.divide(goods.totalAmount, 100)" />
        </div>

      </div>
    </div>

    <p class="title-mark pl12 mt32 mb12">客户数据</p>
    <a-table :columns="customerColumns"
             :data-source="customerList"
             row-key="id"
             :pagination="pagination"
             :scroll="{  y: '100vh' }"
             :loading="tableLoading"
             bordered
             @change="onPaginationChange">
      <template #customer="{ record }">
        <avatar-column :avatar="{avatar: record.userAvatar, name: record.userNickname}">
          <template #bottom>
            <p class="my-4"> <span class="text-color-666"> 姓名：</span> {{record.userRealName}}</p>
            <p><span class="text-color-666"> 手机：</span> {{record.userMobile}}</p>
          </template>
        </avatar-column>
      </template>

      <template #staff="{ record }">
        <template v-if="record.staffWorkWechatThirdAppUserId">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="userName"
               :openid="record.staffWorkWechatThirdAppUserId"></div>
        </template>
        <template v-else> - </template>
      </template>

      <template #payTime="{ text }">
        {{ text ? $f.datetime(text, 'YYYY-MM-DD HH:mm') : ' - ' }}
      </template>

      <template #payAmount="{ text }">
        {{ _.divide(text, 100) }}
      </template>

      <template #action="{ record }">
        <router-link :to="{path:'/customer/customerDetail',query:{ customerId: record.userId, id: record.relationId}}">
          <a-button type="link"
                    :disabled="!record.relationId">客户详情</a-button>
        </router-link>
      </template>
    </a-table>
  </a-spin>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Image, Tooltip, Statistic } from "ant-design-vue";
import { useRoute } from "vue-router";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";

import RouterHeader from "@/components/RouterHeader.vue";
import AvatarColumn from "@/components/AvatarColumn";

import goodsApi from "@/service/api/goods";
import { getWxAgentConfig } from "@/global";

export default defineComponent({
  name: "GoodsManageStatistic",
  props: [],
  emits: [],
  components: {
    AImage: Image,
    ATooltip: Tooltip,
    AStatistic: Statistic,

    RouterHeader,
    AvatarColumn,
  },
  setup() {
    const spinning = ref(true);
    const routerHeaderInfo = [
      {
        path: "/goods/goodsManageIndex",
        name: "商品管理",
      },
      { name: "商品数据" },
    ];

    const route = new useRoute();
    async function getGoodsInfo() {
      await goodsApi
        .getStatisticBaseInfo({ id: route.query.id })
        .then((res) => {
          _.assign(goods, res);
        })
        .finally(() => {
          spinning.value = false;
        });
    }

    const searchForm = reactive({
      id: route.query.id,
      page: 0,
    });

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(
      router,
      searchForm,
      goodsApi.getStatisticPurchaseRecords
    );

    const goods = reactive({
      id: undefined,
      title: "",
      cover: "",
      type: undefined,
      price: undefined,
      buyerNum: undefined,
      createdTime: undefined,
    });

    const customerColumns = [
      {
        title: "客户",
        dataIndex: "userId",
        slots: { customRender: "customer" },
        width: "150px",
      },

      {
        title: "订单所属成员",
        dataIndex: "staffWorkWechatThirdAppUserId",
        slots: { customRender: "staff" },
        width: "120px",
      },

      {
        title: "支付价格（元）",
        dataIndex: "payAmount",
        slots: { customRender: "payAmount" },
        width: "120px",
      },
      {
        title: "购买时间",
        dataIndex: "payTime",
        slots: { customRender: "payTime" },
        width: "120px",
      },

      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
        width: "100px",
      },
    ];

    getGoodsInfo();
    getWxAgentConfig();

    onMounted(() => {});

    return {
      _,
      spinning,
      routerHeaderInfo,
      customerColumns,
      goods,

      customerList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    };
  },
});
</script>
<style lang='less' scoped>
.goods-info {
  &__header {
    .header-item {
      flex: 1;
      padding: 16px;
      height: 216px;
      background-color: @bg-normal;
    }
  }
}

:deep(.ant-tooltip-inner) {
  color: @gray-darker;
  .border-radius();
}
</style>